import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer>
      {!window.location.pathname.includes('/messages') && (
        <Container>
        <Row>
          <Col className='text-center py-3'>
          <Link to={'/terms-and-condition'}>Terms and Condition</Link> | <Link to={'/privacy-policy'}>Privacy Policy</Link><br/>
            <p>Daily Night Write &copy; {currentYear}</p>
            
          </Col>
        </Row>
      </Container>
      )}
    </footer>
  );
};
export default Footer;
