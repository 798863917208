import { Row, Col, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';

const TCScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  
}, []);
 

  return (
    <>
      
      <Row>
        <Col xs={12} sm={12}>
        <h1>Terms and Conditions</h1>
        <section>
            <h2>1. Introduction</h2>
            <p>Welcome to Daily Night Write. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Daily Night Write if you do not agree to take all of the terms and conditions stated on this page.</p>
        </section>
        <section>
            <h2>2. Intellectual Property Rights</h2>
            <p>Other than the content you own, under these terms, Daily Night Write and/or its licensors own all the intellectual property rights and materials contained in this website.</p>
            <p>You are granted a limited license only for purposes of viewing the material contained on this website.</p>
        </section>
        <section>
            <h2>3. Restrictions</h2>
            <p>You are specifically restricted from all of the following:</p>
            <ul>
                <li>Publishing any website material in any other media</li>
                <li>Selling, sublicensing, and/or otherwise commercializing any website material</li>
                <li>Publicly performing and/or showing any website material</li>
                <li>Using this website in any way that is or may be damaging to this website</li>
                <li>Using this website contrary to applicable laws and regulations, or in any way may cause harm to the website, or to any person or business entity</li>
            </ul>
        </section>
        <section>
            <h2>4. Your Content</h2>
            <p>In these terms and conditions, "Your Content" shall mean any audio, video text, images, or other material you choose to display on this website. By displaying Your Content, you grant Daily Night Write a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.</p>
        </section>
        <section>
            <h2>5. No warranties</h2>
            <p>This website is provided "as is," with all faults, and Daily Night Write makes no express or implied representations or warranties, of any kind related to this website or the materials contained on this website. Additionally, nothing contained on this website shall be construed as providing consult or advice to you.</p>
        </section>
        <section>
            <h2>6. Limitation of liability</h2>
            <p>In no event shall Daily Night Write, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise.</p>
        </section>
        <section>
            <h2>7. Severability</h2>
            <p>If any provision of these terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
        </section>
        <section>
            <h2>8. Variation of Terms</h2>
            <p>Daily Night Write is permitted to revise these terms at any time as it sees fit, and by using this website you are expected to review these terms regularly.</p>
        </section>
        <section>
            <h2>9. Entire Agreement</h2>
            <p>These terms constitute the entire agreement between Daily Night Write and you in relation to your use of this website and supersede all prior agreements and understandings.</p>
        </section>
        <section>
            <h2>10. Governing Law & Jurisdiction</h2>
            <p>These terms will be governed by and interpreted in accordance with the laws of the jurisdiction of Daily Night Write, and you submit to the non-exclusive jurisdiction of the state and federal courts located in that jurisdiction for the resolution of any disputes.</p>
        </section>
        </Col>
      </Row>
    </>
  );
};

export default TCScreen;
