import { Row, Col, Button, Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';



const PPScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  
}, []);
 

  return (
    <Container>
      
      <Row>
        <Col xs={12} sm={12}>
        <h1>Privacy Policy</h1>

        <section>
            <h2>Introduction</h2>
            <p>At Daily Night Write, we prioritize the privacy of our visitors. This Privacy Policy document outlines the types of personal information received and collected by our website and how it is used.</p>
        </section>
        <section>
            <h2>Information We Collect</h2>
            <p>We may collect and store personal information that you voluntarily provide to us when using our website. This may include:</p>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Any other information you choose to provide</li>
            </ul>
        </section>
        <section>
            <h2>How We Use Your Information</h2>
            <p>The personal information we collect is used for the following purposes:</p>
            <ul>
                <li>Providing and personalizing our services</li>
                <li>Communicating with you</li>
                <li>Improving our website</li>
                <li>Analyzing trends and user interactions</li>
            </ul>
        </section>
        <section>
            <h2>Third-Party Services</h2>
            <p>We may use third-party services to facilitate our website and services. These services may have their own privacy policies governing the use of your information.</p>
        </section>
        <section>
            <h2>Security</h2>
            <p>We are committed to ensuring the security of your personal information. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.</p>
        </section>
        <section>
            <h2>Changes to This Privacy Policy</h2>
            <p>We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting on this page.</p>
        </section>
        <section>
            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:contact@dailygratitudejournal.com">contact@dailygratitudejournal.com</a>.</p>
        </section>
        </Col>
      </Row>
    </Container>
  );
};

export default PPScreen;
