import { Form, Button, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useState, useEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useGetMessagesQuery } from '../slices/messagesApiSlice';
import { Link,useLocation  } from 'react-router-dom';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Meta from '../components/Meta';
import FormContainer from '../components/FormContainer';
import { useCreateMessageMutation } from '../slices/messagesApiSlice';
import SmallLoader from '../components/SmallLoader';
import { FaAngleLeft,FaUser,FaBrain,FaChevronCircleRight  } from 'react-icons/fa';

const MessagesScreen = () => {
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  var aid = params.get('aid');
 
  const { id: sessionId } = useParams();
  const [message, setMessage] = useState('');
  var urlData = {
    id:sessionId,
    aid:aid
  }
  const { data, isLoading,refetch, error } = useGetMessagesQuery(urlData);
  const [messageArray, setMessageArry]= useState([])
  const containerRef = useRef(null);
  const [createMessage, { isLoading:isLoadings }] = useCreateMessageMutation();
  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    if(data){
      setMessageArry(data.messages)
      
    }
  }, [data]);
  useEffect(() => {
    if(containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth"
      })
    }

  }, [containerRef, messageArray])
 
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric"}
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const updatedMessages = [...messageArray, { messageContent: message,sender:userInfo._id }];
      setMessageArry(updatedMessages);
      setMessage('')
      const res = await createMessage({ message:message,sessionId:sessionId}).unwrap();
      const updatedMessagesWithResponse = [...updatedMessages, { messageContent: res.messageContent, sender:res.sender }];
      setMessageArry(updatedMessagesWithResponse);
      // toast.success('Message Processed successfully');
    } catch (err) {
     // toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <>
      
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta />
          <Link to={`/dashboard`} style={{float:'right'}}>
            <Button className='black-button'><FaAngleLeft></FaAngleLeft> Go Back</Button></Link>
          <h3 style={{marginBottom:'0px',paddingBottom:'0px'}}>Journal for {formatDate(data.session.date)}</h3>
          <Row>
            <div className="chat-container">
              <div className="chat-messages" ref={containerRef}>
            {messageArray.map((msg,index) => (
              <div style={{width:'100%'}}>
                <div key={index}  style={{ width:'100%',height:'auto' }}>
                 <div className={ msg.sender !== userInfo._id ? 'ai-message' : 'user-message'}>
                  {msg.sender !== userInfo._id ? <FaBrain/> : <FaUser/>} {msg.messageContent}</div>
                </div><br/>
                </div>
            ))}
            </div>
            </div>
            
            <FormContainer style={{position:'fixed',paddingTop:'9px'}} className='bottom-input'>
            {isLoadings && <SmallLoader/>}
            <Form onSubmit={submitHandler}>
              <Form.Group  controlId='text'>
                <Form.Label>Press Enter to Submit Message</Form.Label>
                <Row>
                  <Col xs={10}><Form.Control
                  type='text'
                  required
                  placeholder='Enter message'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></Form.Control></Col>
                  <Col xs={2}>
                    <Button className='message-submit'
                    onClick={submitHandler}><FaChevronCircleRight /></Button>
                  </Col>
                </Row>
                
              </Form.Group>
             </Form>
            </FormContainer>
           
           
          </Row>
          
        </>
      )}
        
    </>
  );
};

export default MessagesScreen;
