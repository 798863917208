import { Form, Button, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {useGetSessionsQuery} from '../slices/sessionsApiSlice'
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Meta from '../components/Meta';
import FormContainer from '../components/FormContainer';
import { useCreateMessageMutation } from '../slices/messagesApiSlice';
import React, { useRef, useEffect, useState ,PureComponent} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,Label, ResponsiveContainer } from 'recharts';
import { FaAngleRight, FaPlus,FaCalendarAlt } from 'react-icons/fa';
import grateful from '../assets/grateful.png'


const SessionsScreen = () => {
  const [days, setDays] = useState('');
  const { data, isLoading, error } = useGetSessionsQuery();
  useEffect(() => {
    window.scrollTo(0, 0);
    if(data){
      var date = new Date()
      setDays(date.getDate())  
    }
    
  }, [data]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta />
          {data.charts.length > 0 ? <>
            <Row>
              <Col xs={12} sm={6}>
              <div style={{marginLeft:'auto', marginRight:'auto'}}>
      <h4 className='text-center'>Number of Messages & Words Per Day</h4>
            < >
            <div className='mobileView'>
            <LineChart
          width={370}
          height={250}
          data={data.charts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="SessionDate" />
          <YAxis yAxisId="left" >
          <Label
         style={{
             textAnchor: "middle",
             fontSize: "100%",
         }}
      angle={270} 
      value={"Messages"} />
          </YAxis>
          <YAxis yAxisId="right" orientation="right" >
          <Label
         style={{
             textAnchor: "middle",
             fontSize: "100%",
         }}
      angle={270} 
      value={"Words"} />
            </YAxis>
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="NumberOfMessages" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line yAxisId="right" type="monotone" dataKey="TotalWords" stroke="#82ca9d" />
        </LineChart>
            </div>
      
        <div className='desktopView'>
        <LineChart
          width={480}
          height={250}
          data={data.charts}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="SessionDate" />
          <YAxis yAxisId="left" >
          <Label
         style={{
             textAnchor: "middle",
             fontSize: "100%",
         }}
      angle={270} 
      value={"Messages"} />
          </YAxis>
          <YAxis yAxisId="right" orientation="right" >
          <Label
         style={{
             textAnchor: "middle",
             fontSize: "100%",
         }}
      angle={270} 
      value={"Words"} />
            </YAxis>
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="NumberOfMessages" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line yAxisId="right" type="monotone" dataKey="TotalWords" stroke="#82ca9d" />
        </LineChart>
        </div>
        
        </>
    </div>
              </Col>
              <Col xs={12} sm={6}>
              <h4 className='text-center'>Track your Progress</h4>
              <div class="card" >
                <div class="card-body">
                  <h5 class="card-title">This Month</h5>
                  <h6 class="card-subtitle mb-2">Number of Daily Check Ins</h6>
                  <span style={{color:'#fff', fontWeight:'bold'}}><span style={{fontWeight:'bold',fontSize:'39px'}}>{data.numofdays}</span>/{days} days</span>
                  <h5>Current Streaks: <span style={{color:'#fff'}}>{data.consecutiveStreaks}</span></h5>
                </div>
                
              </div>
              <Link to={`/messages`}>
                <Button style={{float:'right',marginTop:'30px'}} className='yellow-button'><FaPlus></FaPlus> Write Today's Journal</Button></Link>
              </Col>
            </Row>
      
    <hr/>

    <Row>    <br/>
      <h3 style={{marginTop:'18px'}}>Daily Journaling</h3>
            <div style={{ width: '100%', padding: '4px',textAlign:'center'}}>
            { data.chartslist.map((session,index) => (
                <Row key={index}  style={{  padding: '10px', marginBottom: '10px',borderBottom:'1px solid #333', borderRadius: '10px' }}>
                  <Col style={{textAlign:'left'}}>
                  <FaCalendarAlt></FaCalendarAlt>{' '}<a href={`/messages/${session._id}`}>{session.SessionDate.split('/')[1]}/{session.SessionDate.split('/')[0]}/2024</a><br/>
                  <small>Number of Entries: <strong>{session.NumberOfMessages}</strong></small><br/>
                  <small>Word Count: <strong>{session.TotalWords}</strong></small>
                  </Col>
                    <Col style={{textAlign:'right'}}>
                    <Link to={`/messages/${session._id}`}><Button className='black-button'> <FaAngleRight></FaAngleRight> Read</Button></Link>
                    </Col>
                  
                </Row>
            ))}
            </div>
            
          </Row>
          </>:
          <div className='text-center' style={{marginTop:'65px'}} >
          <img src={grateful} alt='Daily Gratitude Journal' width={'200px'}/>
              <h2>Stay Grateful</h2>
            <Link to={`/messages`} ><Button>Start Your Journal</Button></Link><br/><br/>
            <div style={{textAlign:'center'}} className='text-center'> 
            By taking just a few moments each day to reflect on the blessings, big or small, that enrich our lives,<br/> we can cultivate a deeper sense of contentment, resilience, and joy.</div>
            </div>
}
          
      
          
        </>
      )}
    </>
  );
};

export default SessionsScreen;
