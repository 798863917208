import { apiSlice } from './apiSlice';
import { MESSAGES_URL } from '../constants';

export const messagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMessages: builder.query({
      query: (data) => ({
        url: `${MESSAGES_URL}/${data.id}?aid=${data.aid}`,
      }),

      providesTags: ['Message'],
      keepUnusedDataFor: 5,
    }),
    createMessage: builder.mutation({
      query: (message) => ({
        url: MESSAGES_URL,
        method: 'POST',
        body: message,
      }),
    }),
  }),
});

export const { useGetMessagesQuery, useCreateMessageMutation } =
  messagesApiSlice;
