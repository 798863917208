import { apiSlice } from './apiSlice';
import { SESSIONS_URL } from '../constants';

export const sessionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSessions: builder.query({
      query: () => ({
        url: SESSIONS_URL,
      }),
      providesTags: ['Session'],
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetSessionsQuery } = sessionsApiSlice;
