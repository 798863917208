import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: 'Welcome To Daily Night Write',
  description: 'Our platform offers a simple yet powerful tool to help you embrace gratitude as a daily practice, enriching your life one thankful moment at a time',
  keywords: 'gratitude, daily practice, positivity',
};

export default Meta;
