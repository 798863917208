import { Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'
import banner from '../assets/banner.png'

import { FaBook, FaLock,FaDatabase,FaUserShield } from 'react-icons/fa';


const HomeScreen = () => {

  return (
    <>
      
      <Row>
        
        <Col xs={12} sm={12} className='text-center' >
        <img src={logo} width={'10%'}/>
          <br/>
        <h1>The Journal That Talks Back</h1>
        <p style={{color:'#9e9e9e', textTransform:'capitalize'}}>
        transforms journaling into a stress-relieving, memory-enhancing, and enjoyable experience, powered by the innovation of AI. It merges the therapeutic benefits of journaling with the excitement of AI, making it a unique resource for personal growth and learning.
        </p>
        <br/>
        <h4 style={{color:'#fff'}}>Ready to Become Your Best Self?</h4>
        <Link to={'/register'}><Button className='yellow-button'><FaBook></FaBook>{' '}Write Your Todays Journal</Button></Link><br/><br/>
        <small>The #1 AI-powered journal for mental health and personal growth</small>
        <h2 style={{color:'#ffffff'}}>Discover Meaning in Daily Living</h2>
        </Col>
      </Row><hr/>
      <Row>
        {/* <Col xs={12} sm={6}>
          <img src={banner} width={'80%'} style={{borderRadius:'20px'}}/>
        </Col> */}
        <Col  xs={12} sm={12} style={{paddingTop:'10px'}}>
          
        <h2>Discover the Joy and Benefits of AI-Powered Journaling with Insight AI</h2>

<p style={{color:'#fff'}}>In today's fast-paced world, finding a moment for ourselves, reflecting on our experiences, and nurturing our mental health are more important than ever. That's where Insight AI steps in, revolutionizing the traditional journaling experience with the power of artificial intelligence. Insight AI is not just an app; it's your personal journaling companion, designed to make self-reflection, stress reduction, and memory improvement not only beneficial but also immensely enjoyable.</p>

<h2>Why Choose Insight AI for Your Journaling Journey?</h2>

<ul style={{color:'#fff'}}>
  <li><strong>Stress Relief at Your Fingertips:</strong> Life can be overwhelming, but with Insight AI, you have a private, secure space to express your thoughts, concerns, and dreams. Our AI-powered prompts are tailored to help you unwind and process your day, reducing stress and anxiety through the simple act of writing.</li>
  
  <li><strong>Boost Your Memory and Learning:</strong> Regularly engaging with Insight AI encourages you to reflect and articulate your experiences, significantly enhancing your memory recall and cognitive clarity. It's like a workout for your brain, but much more fun!</li>
  
  <li><strong>A Personalized Journaling Experience:</strong> Insight AI learns from your interactions, offering personalized prompts, suggestions, and reflections that make journaling deeply rewarding. Whether you're exploring personal growth, tracking goals, or simply looking for a daily writing escape, Insight AI adapts to your needs.</li>
  
  <li><strong>Fun and Engaging:</strong> Who said journaling had to be monotonous? With Insight AI, each journaling session is a new adventure. Enjoy creative prompts, playful challenges, and the occasional whimsical AI companion commentary, making your journaling time something to look forward to.</li>
  
  <li><strong>Safe and Secure:</strong> Your privacy is paramount. With state-of-the-art encryption and privacy measures, your journal entries are always protected, ensuring a safe space for your most intimate thoughts and reflections.</li>
</ul>

<h2>Join the Insight AI Community</h2>

<p style={{color:'#fff'}}>By choosing Insight AI, you're not just adopting a new journaling habit; you're joining a community of like-minded individuals who value self-reflection, personal growth, and the innovative use of technology to enhance our daily lives. With Insight AI, journaling becomes a key part of your journey towards a more mindful, balanced, and joyful life.</p>

<p style={{color:'#fff'}}>Dive into the world of AI-powered journaling today with Insight AI and discover a more relaxed, reflective, and enriched self. Your journey towards personal growth and improved mental health starts here. Welcome aboard!</p>
<h1>Discover the Joy and Benefits of AI-Powered Journaling with Insight AI</h1>

<p style={{color:'#fff'}}>In today's fast-paced world, finding a moment for ourselves, reflecting on our experiences, and nurturing our mental health are more important than ever. That's where Insight AI steps in, revolutionizing the traditional journaling experience with the power of artificial intelligence. Insight AI is not just an app; it's your personal journaling companion, designed to make self-reflection, stress reduction, and memory improvement not only beneficial but also immensely enjoyable.</p>

<h2>Why Choose Insight AI for Your Journaling Journey?</h2>

<ul style={{color:'#fff'}}>
  <li><strong>Stress Relief at Your Fingertips:</strong> Life can be overwhelming, but with Insight AI, you have a private, secure space to express your thoughts, concerns, and dreams. Our AI-powered prompts are tailored to help you unwind and process your day, reducing stress and anxiety through the simple act of writing.</li>
  
  <li><strong>Boost Your Memory and Learning:</strong> Regularly engaging with Insight AI encourages you to reflect and articulate your experiences, significantly enhancing your memory recall and cognitive clarity. It's like a workout for your brain, but much more fun!</li>
  
  <li><strong>A Personalized Journaling Experience:</strong> Insight AI learns from your interactions, offering personalized prompts, suggestions, and reflections that make journaling deeply rewarding. Whether you're exploring personal growth, tracking goals, or simply looking for a daily writing escape, Insight AI adapts to your needs.</li>
  
  <li><strong>Fun and Engaging:</strong> Who said journaling had to be monotonous? With Insight AI, each journaling session is a new adventure. Enjoy creative prompts, playful challenges, and the occasional whimsical AI companion commentary, making your journaling time something to look forward to.</li>
  
  <li><strong>Safe and Secure:</strong> Your privacy is paramount. With state-of-the-art encryption and privacy measures, your journal entries are always protected, ensuring a safe space for your most intimate thoughts and reflections.</li>
</ul>

<h2>Join the Insight AI Community</h2>

<p style={{color:'#fff'}}>By choosing Insight AI, you're not just adopting a new journaling habit; you're joining a community of like-minded individuals who value self-reflection, personal growth, and the innovative use of technology to enhance our daily lives. With Insight AI, journaling becomes a key part of your journey towards a more mindful, balanced, and joyful life.</p>

<p style={{color:'#fff'}}>Dive into the world of AI-powered journaling today with Insight AI and discover a more relaxed, reflective, and enriched self. Your journey towards personal growth and improved mental health starts here. Welcome aboard!</p>

        </Col>
      </Row>
    </>
  );
};

export default HomeScreen;
